import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
	Image,
	Paragraph,
	Heading
} from 'reakit'

import { Container, PhoneBody } from '../../components/layouts'

import {
	DonationItem,
	Loader
} from '../../components/ui'

import {
	getAccountPerformance,
	refreshToken
} from '../../factory'

import currencyFormat from '../../util/currencyFormat'

import Layout from '../../components/layout'

import theme from '../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			showRoundups: true
		}
		this.selectTab = this.selectTab.bind(this)
		this.renderDonations = this.renderDonations.bind(this)
		this.renderTransactions = this.renderTransactions.bind(this)
	}

	componentDidMount() {
		this.props.getAccountPerformance(this.props.token)
	}

	selectTab(showRoundups) {
		this.setState({showRoundups})
	}

	renderDonations(donations) {
		if (donations.length > 0) {
			return donations.map((item) => <DonationItem item={item}/>)
		}
		if(this.props.error) {
			<Flex column style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
				<Image
					src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding4.png"}
					width={"150px"}
					height={'auto'}
				/>
				<Paragraph style={{width: '85%', marginTop: 10, textAlign: 'center'}}>We had an error fetching your account history. Please try logging out and logging back in.</Paragraph>
			</Flex>
		}
		return (
			<Flex column style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
				<Image
					src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding4.png"}
					width={"150px"}
					height={'auto'}
				/>
				<Paragraph style={{width: '85%', marginTop: 10, textAlign: 'center'}}>We don't have any donations yet, make sure you have selected a payment account in your profile and check back in a few days!</Paragraph>
			</Flex>
		)
	}

	renderTransactions(transactions) {
		if (transactions.length > 0) {
			return (transactions.map((item) => <DonationItem item={item}/>))
		}
		if(this.props.error) {
			<Flex column style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
				<Image
					src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding4.png"}
					width={"150px"}
					height={'auto'}
				/>
				<Paragraph style={{width: '85%', marginTop: 10, textAlign: 'center'}}>We had an error fetching your account history. Please try logging out and logging back in.</Paragraph>
			</Flex>
		}
		return (
			<Flex column style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 25}}>
				<Image
					src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2.png"}
					width={"150px"}
					height={'auto'}
				/>
				<Paragraph style={{width: '85%', marginTop: 10, textAlign: 'center'}}>We don't have any transactions yet, make sure you have linked roundup cards in your profile and check back in a few days!</Paragraph>
			</Flex>
		)
	}

	render() {
		const {total_sum, daily_donations, transactions, donations, pending, error} = this.props
		const typed_daily_deposits = daily_donations.map((item) => {return ({...item, type: 1})})
		const typed_transactions = transactions.map((item) => {return ({...item, type: 0})})
		const total_transactions = typed_transactions.concat(typed_daily_deposits)
		total_transactions.sort((a, b) => new Date(b.date) - new Date(a.date))

		const pendingRoundUps = total_transactions.filter((item) => item.type + item.status === 1)
		const currentRoundups = pendingRoundUps.reduce((a, b) => a + (b.sum || 0), 0)

		const {showRoundups} = this.state
		if (pending) {
			return (
				<Layout>
					<Container>
						<PhoneBody title={'My Donations'}>
							{error && <Flex column style={{background: theme.colors.crusta, width: '100%', paddingLeft: 20, paddingTop: 20, paddingBottom: 10, color: theme.colors.dustGray}}>
								<Paragraph marginBottom={3} color={'white'}>{error}</Paragraph>
							</Flex>}
							<Flex column style={{background: '#F2F5F5', width: '100%', alignItems: 'center', paddingTop: 32, paddingBottom: 20, color: theme.colors.dustGray}}>
								<div style={{textAlign: 'center'}}>
									<Paragraph>{currencyFormat(3.42)} until your next donation</Paragraph>
									<Paragraph fontSize={62} color={'black'} lineHeight={'0.8em'}>$1.58</Paragraph>
								</div>
							</Flex>
							<Flex row style={{width: '100%', alignItems: 'center', color: theme.colors.dustGray, textAlign: 'center'}}>
								<div
									onClick={()=>this.selectTab(true)}
									style={{flex: 1,
										cursor: "pointer",
										borderRight: `2px solid #e1e0e1`,
										padding: "10px 5px",
										color: showRoundups && 'black',
										borderBottom: `5px solid ${showRoundups ? theme.colors.sunglow : '#e1e0e1'}`}}>
									<Paragraph>My Transactions</Paragraph>
								</div>
								<div
									onClick={()=>this.selectTab(false)}
									style={{flex: 1,
										padding: "10px 5px",
										cursor: "pointer",
										color: !showRoundups && 'black',
										borderBottom: `5px solid ${!showRoundups ? theme.colors.sunglow : '#e1e0e1'}`}}>
									<Paragraph>My Donations</Paragraph>
								</div>
							</Flex>
							<div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
								<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
									<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
										<Loader/>
										<Heading as="h1" fontSize={24}>Loading...</Heading>
									</div>
								</div>
							</div>
						</PhoneBody>
					</Container>
				</Layout>
			)
		}
		return (
			<Layout>
				<Container>
					<PhoneBody title={'My Donations'}>
						{error && <Flex column style={{background: theme.colors.crusta, width: '100%', paddingLeft: 20, paddingTop: 20, paddingBottom: 10, color: theme.colors.dustGray}}>
							<Paragraph marginBottom={3} color={'white'}>{error}</Paragraph>
						</Flex>}
						<Flex column style={{background: '#F2F5F5', width: '100%', alignItems: 'center', paddingTop: 32, paddingBottom: 20, color: theme.colors.dustGray}}>
							{showRoundups ?
								<div style={{textAlign: 'center'}}>
									<Paragraph>{currencyFormat(5 - currentRoundups)} until your next donation</Paragraph>
									<Paragraph fontSize={62} color={'black'} lineHeight={'0.8em'}>{currencyFormat(currentRoundups)}</Paragraph>
								</div>
								:
								<div style={{textAlign: 'center'}}>
									<Paragraph>So far, you've given</Paragraph>
									<Paragraph fontSize={62} color={'black'} lineHeight={'0.8em'}>{currencyFormat(total_sum)}</Paragraph>
								</div>
							}
						</Flex>
						<Flex row style={{width: '100%', alignItems: 'center', color: theme.colors.dustGray, textAlign: 'center'}}>
							<div
								onClick={()=>this.selectTab(true)}
								style={{flex: 1,
									cursor: "pointer",
									borderRight: `2px solid #e1e0e1`,
									padding: "10px 5px",
									color: showRoundups && 'black',
									borderBottom: `5px solid ${showRoundups ? theme.colors.sunglow : '#e1e0e1'}`}}>
								<Paragraph>My Transactions</Paragraph>
							</div>
							<div
								onClick={()=>this.selectTab(false)}
								style={{flex: 1,
									padding: "10px 5px",
									cursor: "pointer",
									color: !showRoundups && 'black',
									borderBottom: `5px solid ${!showRoundups ? theme.colors.sunglow : '#e1e0e1'}`}}>
								<Paragraph>My Donations</Paragraph>
							</div>
						</Flex>
						{showRoundups && this.renderTransactions(total_transactions)}
						{!showRoundups && this.renderDonations(donations) }
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	token: PropTypes.string,
	getAccountPerformance: PropTypes.func,
	user: PropTypes.object,
	total_sum: PropTypes.string,
	daily_donations: PropTypes.array,
	transactions: PropTypes.array,
	donations: PropTypes.array,
	error: PropTypes.string,
	pending: PropTypes.bool
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		transactions: state.accountState.transactions,
		donations: state.accountState.donations,
		pending: state.accountState.pending,
		token: state.authState.accessToken,
		total_sum: state.accountState.total_sum,
		daily_donations: state.accountState.daily_donations,
		error: state.accountState.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getAccountPerformance: (token) => dispatch(getAccountPerformance(token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
